@import '../settings.scss';

.icon {
    height: 100%; 
    width: 100%;     
}

.toggle-icon {
    position: absolute; 
    height: 100%; 
    width: 100%; 
    top: 0;
    left: 0; 
}

.sharing-link {
    padding: vh(1);
}

.sharing-link img {
    width: vh(6);
    height: vh(6);
}

.info-panel-icon {
    width: vh(5);
    height: vh(5);
}

.icon-row {
    width: 100%;
}

.coming-soon-icon-row {
    margin: 5vh 0 5vh 0;
}

.icon-row-child {
    display: flex;
    align-items: center;
    justify-content: center;
    width: vh(6);
    height: vh(6);
    padding: vh(1);
}

.icon-row-child:first-child {
    margin-left: 0;
}

.icon-row-child:last-child {
    margin-right: 0;
}

.menu-button-icon {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
}

.icon-white {
    fill: white;
    stroke: white;
}

.icon-moon {
    fill: $moon-yellow;
    stroke: $moon-yellow;
}

.rotate45 {
    transform: rotateZ(45deg);
}

.scale-div {
    display: inline-block;
    position: absolute; 
    transition-duration: 250ms;
    transform-origin: 50% 50%;
    top: 25%;
    left: 25%;
    height: 50%;
    width: 50%;
    margin: auto;
}

.scale-div-morph {
    display: inline-block;
    position: absolute; 
    transform-origin: 50% 50%;
    top: 37.5%;
    left: 37.5%;
    height: 25%;
    width: 25%;
    margin: auto;
}

#icon-play3-poly {
    transform-origin: 50% 50%;
}

#icon-plus {
    transition-duration: 250ms;
}

#icon-info {
    top: -20%;
    left: -20%;
    height: 140%;
    width: 140%;
}