@import '../settings.scss';

.slider-label {
    padding-top: vh(1.9);
    padding-bottom: vh(0.8);
}

.slider-row {
  padding-top: vh(1);
  padding-bottom: vh(1);
}

.canvas-slider {
  padding: 0;
  margin: 0;
  height: 100%;
  width: 100%;
}

.canvas-slider-wrapper {
  cursor: ew-resize;
  height: vh(6.5);
  flex: 1 1 0;
  height: 3vh;
  width: 100%;
}

#effects-controls-row {
  margin-top: vh(4);
}

#effects-panel input {
    width: 100%
}

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: vh(6);
    height: vh(3);
  }
  
  /* Hide default HTML checkbox */
  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  
  /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
  }
  
  .slider:before {
    position: absolute;
    content: "";
    height: vh(2);
    width: vh(2);
    left: vh(.5);
    bottom: vh(.5);
    background-color: white;
  }
  
  input:checked + .slider {
    background-color: $hot-green;
  }
  
  input:focus + .slider {
    box-shadow: 0 0 1px $hot-green;
  }
  
  input:checked + .slider:before {
    -webkit-transform: translateX(vh(3));
    -ms-transform: translateX(vh(3));
    transform: translateX(vh(3));
  }
  
  /* Rounded sliders */
  .slider.round {
    border-radius: vh(1.75);
  }
  
  .slider.round:before {
    border-radius: 50%;
  }