@import '../settings.scss';

.menu-button {
    position: absolute;
    display: inline-block;
    height: auto;
    width: auto;
}

.menu-button-parent {
    position: relative;
    border: vh(.2) solid white;
    border-radius: 50%;
}