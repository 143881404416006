@import './settings.scss';

/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
         url('../fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
  }

/* satisfy-regular - latin */
@font-face {
    font-family: 'Satisfy';
    font-style: normal;
    font-weight: 400;
    src: url('../fonts/satisfy-v10-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Satisfy Regular'), local('Satisfy-Regular'),
         url('../fonts/satisfy-v10-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
         url('../fonts/satisfy-v10-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
         url('../fonts/satisfy-v10-latin-regular.woff') format('woff'), /* Modern Browsers */
         url('../fonts/satisfy-v10-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
         url('../fonts/satisfy-v10-latin-regular.svg#Satisfy') format('svg'); /* Legacy iOS */
  }

html {
    font-size: 52.5%;
}

body {
    font-family: 'Lato', Helvetica, sans-serif;
    font-weight: normal;
    background-color: $off-black-2;
}

canvas {
    display: block;
}

h1, h2, h3, h4, h5, h6 {
    font-weight: normal;
}

a {
    color: inherit;
}

button, .button {
    cursor: pointer;
}

button:focus {
    outline: none;
}

.flex-row {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.flex-col {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.fullscreen {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    margin: 0;
    width: vw(100);
    height: vh(100);
}

.transparent {
    background-color: rgba(0,0,0,0);
}

.off-black {
    background-color: $off-black-2;
}

.front-most {
    z-index: 999;
}

.hot-pink {
    color: $hot-pink;
}

.hot-green {
    color: $hot-green;
}

.hot-blue {
    color: $hot-blue;
}

.announcement {
    background-color: $off-black-2;
    font-size: $m-size;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0px;
    height: 50px;
    width: 100vw;
}