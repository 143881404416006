@import '../settings.scss';

.new-label {
    position: absolute;
    top: 15px;
    left: 15px;
    font-size: 1.6rem;
    color: $hot-green;
}

.custom-song-icon {
    display: block;
    margin: 0;
    width: 25rem;    
    height: 25rem;
}

.custom-song-icon-mobile {
    display: block;
    margin: 0;
    width: 15rem;
    height: 15rem;
}

#loading-button-wrapper {
    width: 25rem;
    height: 25rem;
}

#loading-button {
    position: absolute;
    width: 25rem;
    height: 25rem;
    margin: 0;
    padding: 0px;
    text-align: center;
    vertical-align: middle;
    border: none;
    border-radius: 50%;
    background-color: rgba(255, 0, 0, 0);
}

#loading-button * {
    text-transform: none;
    font-size: $m-size;
    color: $moon-yellow;
}