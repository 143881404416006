@import '../settings.scss';

.toggle-button {
    position: relative;
    border-radius: 50%;
    border: none;
    padding: 0;
    overflow-wrap: break-word;
    background-color: rgba(255, 255, 255, 0);
    z-index: 99;
    margin-right: vh(3);
    cursor: pointer;
} 

.toggle-button:focus {
    outline: none;
}

.toggle-button:last-child {
    margin-right: 0;
}

.svg-circle {
    position: absolute;
    display: inline;
    top: 0;
    left: 0;
    fill-opacity: 0;
    stroke: white;
    stroke-linecap: round;
    transform-origin: 50% 50%;
}