@import '../settings.scss';

.announcement {
    background-color: $off-black-2;
    font-size: $m-size;
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    bottom: 20px;
    width: 100%;
}

#landing-page-canvas {
    z-index: -1;
}

#landing-page-soundscape-title-wrapper {
    padding-top: 10rem;
}

@media screen and (max-width: 670px) {
    #landing-page-soundscape-title-wrapper {
        padding-top: 3rem;
    } 
}

#landing-page-soundscape-title {
    font-family: 'Satisfy';
    font-size: $xxl-size;
    font-weight: 400;
    margin: 0;
    padding: 2rem;
    color: $font-color; // Fallback for non-webkit - test this
    // `background` must come before `background-clip` in this stylesheet
    background: linear-gradient(30deg, #feac5e, #f3a280, #e7979b, #db8cb2, #ce80c7, #be8ad4, #aaa6dc, #94bfe4, #7ad4eb, #59e8f2);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

@media screen and (max-width: 670px) {
    #landing-page-soundscape-title {
        font-size: 7.5rem;
    } 
}

.landing-page-header {
    font-size: $m-size;
    color: $font-color;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.landing-page-header .flex-row {
    justify-content: center;
}

#landing-page {
    overflow: auto;
}

#landing-page-song-title {
    color: $hot-pink;
}

#landing-page-bpm {
    color: $hot-green;
}

#landing-page-key {
    color: $hot-blue;
}

.filler{
    display: inline-block;
    width: 100%;
    height: 25rem
}

.mobile-song-icon-wrapper {
    border-color: $moon-yellow;
    border-width: 1px;
    border-style: solid;
    border-bottom-width: 0px;
}

#song-selection-panel {
    width: vw(75);
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-start;
    align-content: flex-start;
}

.song-link {
    border-radius: 50%;
}

.song-link:hover {
    background-color: rgba(255,255,255,.05);
    backdrop-filter: blur(3px);
}

.song-link-mobile {
    text-decoration: none;
    max-width: 320px;
    display: block;
    border-radius: 1rem;
    width: 100%;
    margin: 1rem 0;
}

.song-link-mobile>div {
    flex-basis: 50%;
}

.song-link-mobile:hover {
    background-color: rgba(255,255,255,.05);
    backdrop-filter: blur(3px);
}

.info-row {
    margin-bottom: 1.5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.info-row>p {
    text-align: center;
}

.info-subheader {
    font-size: 3rem;
    margin-bottom: 4rem;
    text-transform: none;
    max-width: 375px;
    text-wrap: wrap;
    text-align: center;
}

.info-page-button {
    font-size: $m-size;
    border-radius: 4px;
    width: 140px;
  }
  
.info-page-button:hover {
    background-color: rgba(255, 255, 255, 0.1);
    transition-duration: 250ms;
}

span,p {
    font-size: 1.75rem;
}