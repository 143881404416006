@import '../settings.scss';

.menu-button-child {
    transition-duration: 200ms;
    position: absolute;
    border-radius: 50%;
    border: vh(.2) solid white;
}

.arrow {
    position: absolute;
    width: 0; 
    height: 0; 
    border-left-color: transparent;
    border-left-style: solid;
    border-right-color: transparent;
    border-right-style: solid;
    border-bottom-style: solid;
  }